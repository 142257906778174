import React from "react"
import { Link } from "react-router-dom"
import CarouselComp from "../components/Carousel"
import "react-responsive-carousel/lib/styles/carousel.min.css"
import ScrollButton from "../components/ScrollButton";

export default function Home() {
	return (
		<div>
			<div className="dummy"></div>
			<div className="carousel-wrapper">
				<CarouselComp />
			</div>

			<section className="services">
				<div className="services-title">
					<h2>Szolgáltatásaink</h2>
				</div>
				<div className="services-description">
					<h4>
						Vállalkozások és magánszemélyek számára nyújtunk könyvelési,
						bérszámfejtési és tanácsadási, valamint IT szolgáltatásokat.
					</h4>
				</div>
			</section>
			<section className="services-second">
				<div className="services-list">
					<Link to="/about" className="icon-box">
						<div className="icon-box-top">
							<div>
								<i className="fa-regular fa-file-lines fa-2xl"></i>
							</div>
							<h5 className="service-title">Könyvelés</h5>
						</div>
						<div className="box-divider"></div>
					</Link>
					<Link to="/about" className="icon-box">
						<div className="icon-box-top">
							<div>
								<i className="fa-regular fa-id-badge fa-2xl"></i>
							</div>
							<h5 className="service-title">Bérszámfejtés</h5>
						</div>
						<div className="box-divider"></div>
					</Link>
					<Link to="/about" className="icon-box">
						<div className="icon-box-top">
							<div>
								<i className="fa-regular fa-chart-bar fa-2xl"></i>
							</div>
							<h5 className="service-title">Tanácsadás</h5>
						</div>
						<div className="box-divider"></div>
					</Link>
					<Link to="/it" className="icon-box">
						<div className="icon-box-top">
							<div>
								<i className="fa-solid fa-computer fa-2xl"></i>
							</div>
							<h5 className="service-title">IT szolgáltatás</h5>
						</div>
						<div className="box-divider"></div>
					</Link>
				</div>
			</section>
			<section className="home-about-us">
				<div className="home-container-about">
					<div className="home-about-inner">
						<h2 className="home-about-title">Rólunk</h2>
						<div className="home-about-description">
							<p>
								Cégünk a Rendbe-Rak Kft. egy családi vállalkozás, mely 1992 óta
								végez könyvelési és bérszámfejtési szolgáltatást. Teljeskörű
								könyvelés, bérszámfejtés mikro-, kis-, és középvállalkozások,
								egyéni vállalkozók, egyesületek, alapítványok és egyéb gazdasági
								társaságok részére.
							</p>
						</div>
						<div className="home-about-check">
							<p>
								<i
									className="fa-solid fa-check fa-lg"
									style={{ color: "#F17B02" }}
								></i>
								Hozzáértő szakemberek
							</p>
							<p>
								<i
									className="fa-solid fa-check fa-lg"
									style={{ color: "#F17B02" }}
								></i>
								Megfizethető ár
							</p>
						</div>
						
							<Link to="/contact" className="home-about-button">
								Kapcsolat <i className="fa-solid fa-angle-right fa-2xs"></i>
							</Link>
						
					</div>
				</div>
			</section>
			<section className="why">
				<div className="why-wrapper">
					<h2>Miért a Rendbe-Rak?</h2>
					<p>
						Elkötelezettek vagyunk amellett, hogy a legmagasabb fokú
						őszinteséggel és feddhetetlenséggel professzionális szolgáltatásokat
						nyújtsunk, és arra törekszünk, hogy hozzáadott értéket adjunk
						könyvelési és bérszámfejtési szolgáltatásainkhoz.
					</p>
				</div>
			</section>
			<section className="team">
				<div className="team-wrapper">
					<div className="team-box">
						<img
							src="images/ff.jpg"
							alt="Rendbe-Rak Kft logo"
							className="img-thumb"
							loading="lazy"
						/>
						<div className="team-contact-compose"></div>
						<div className="team-contact-compose-data">
							<p>
								<i className="fa-solid fa-phone thumb-icon"></i> +36 30 626 1123
							</p>
							<p>
								<i className="fa-regular fa-envelope thumb-icon"></i>
								f.ferenc@rendberak.hu
							</p>
						</div>
						<div className="team-contact">
							<h3 className="team-name">Fridrich Ferenc</h3>
							<hr className="team-divider"></hr>
							<h5 className="team-position">Tulajdonos</h5>
						</div>
					</div>
					<div className="team-box">
						<img
							src="images/fd.jpg"
							alt="Rendbe-Rak Kft logo"
							className="img-thumb"
							loading="lazy"
						/>
						<div className="team-contact-compose"></div>
						<div className="team-contact-compose-data">
							<p>
								<i className="fa-regular fa-envelope thumb-icon"></i>
								f.dominika@rendberak.hu
							</p>
						</div>
						<div className="team-contact">
							<h3 className="team-name">Fridrich Dominika</h3>
							<hr className="team-divider"></hr>
							<h5 className="team-position">Tulajdonos</h5>
						</div>
					</div>
					<div className="team-box">
						<img
							src="images/fm.jpg"
							alt="Rendbe-Rak Kft logo"
							className="img-thumb"
							loading="lazy"
						/>
						<div className="team-contact-compose"></div>
						<div className="team-contact-compose-data">
							<p>
								<i className="fa-solid fa-phone thumb-icon"></i> +36 30 560 3157
							</p>
							<p>
								<i className="fa-regular fa-envelope thumb-icon"></i>
								f.marta@rendberak.hu
							</p>
						</div>
						<div className="team-contact">
							<h3 className="team-name">Fridrichné Márta</h3>
							<hr className="team-divider"></hr>
							<h5 className="team-position">Tulajdonos</h5>
						</div>
					</div>
				</div>
			</section>
			<ScrollButton />
		</div>
	)
}
