import React, {useState, useEffect} from 'react'
import ScrollButton from "../components/ScrollButton";
import "./contact.css"

export default function Contact() {

	const url =
		process.env.REACT_APP_NODE_ENV === "production"
			? process.env.REACT_APP_PROD_API_URL
			: process.env.REACT_APP_DEV_API_URL

	const [loading, setLoading] = useState(true)
	const [btnSpinLogo, setBtnSpinLogo] = useState(false)
	const [btnText, setbtnText] = useState(
		"Küldés "
	)
	const [defaultBtnIcon, setDefaultBtnIcon] = useState(
		<i className="fa-regular fa-paper-plane fa-lg fa-bold"></i>
	)

	const [mailerState, setMailerState] = useState({
		token: "",
		a_password: "",
		email: "",
		name: "",
		message: "",
	})

	function resetMailerState(){
		setMailerState({
			token: "",
			a_password: "",
			email: "",
			name: "",
			message: "",
		})
	}
	

	useEffect(() => {
		// Load reCAPTCHA script and initialize
		if(!document.getElementById("captcha")){
			const script = document.createElement("script")
			script.src = `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_SITE_KEY}`
			document.body.appendChild(script)
			script.setAttribute("id", "captcha")
	
			script.onload = () => {
				// Initialize reCAPTCHA with your site key
				window.grecaptcha.ready(() => {
					// You can use this callback to execute code after reCAPTCHA script is loaded and ready
					setLoading(false)
				})
			}
		}
	}, [])

	function handleStateChange(e) {
		setMailerState((prevState) => ({
			...prevState,
			[e.target.name]: e.target.value,
		}))
	}

	function handleToChangeBtnCheck(){
		setbtnText("elküldve")
		setDefaultBtnIcon(
			<i className="fa-solid fa-check fa-lg" style={{ color: "#fff" }}></i>
		)
		changeToDefaultBtn()
	}

	function changeToDefaultBtn(){
		setTimeout(() => {
		  setDefaultBtnIcon(<i className="fa-regular fa-paper-plane fa-lg"></i>)
		  setbtnText("Küldés ")
		  setLoading(false)
		}, 4000); 
	}

	const handleRecaptcha = async () => {
		if (typeof window !== "undefined" && window.grecaptcha) {
			const token = await window.grecaptcha.execute(
				process.env.REACT_APP_SITE_KEY,
				{
					action: "submit",
				}
			)
			return token
		}
		return ""
	}

	const handleSubmit = async (e)=> {
		e.preventDefault()
		setLoading(true)
		setDefaultBtnIcon("")
		setbtnText("Küldés...")
		setBtnSpinLogo(true)
		const payload = {...mailerState}

		try {
			const token = await handleRecaptcha() // Call handleRecaptcha to get the token
			
			if (token) {
				payload.token = token
				const response = await fetch(url + "/contact", {
					method: "POST",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify({ payload }),
				})
				// eslint-disable-next-line
				const res = await response.json()
				console.log(res)
				// Handle the response from your Node.js API
				setBtnSpinLogo(false)
				resetMailerState()
				handleToChangeBtnCheck()
			} else {
				console.log('no token')
			}
		} catch (error) {
			setBtnSpinLogo(false)
			setbtnText("Hiba, kérjük próbálja később")
			setDefaultBtnIcon(
				<i
					style={{ color: "#fff" }}
					className="fa-solid fa-triangle-exclamation fa-lg"
				></i>
			)
			changeToDefaultBtn()
			resetMailerState()
		}		
	}

	return (
		<div>
			<div className="dummy"></div>
			<ScrollButton />
			<section className="contact">
				<div className="contact-description">
					<div className="contact-description-left">
						<h1>Hogyan találhat meg minket?</h1>
						<h6 className="contact-how">
							Ha kérdése van, csak töltse ki a kapcsolatfelvételi űrlapot és
							hamarosan válaszolunk. Kérjen időpontot és látogasson meg minket
							kényelmes irodánkban.
						</h6>
						<div className="contact-address">
							<h3>Irodánk</h3>
							<h4 className="contact-address-text">
								1171 Budapest, Fehértemplom utca 8.
							</h4>
							<h4 className="contact-address-phone">
								<strong>Telefon:</strong> <span>+36 30 626 1223</span>
							</h4>
							<h4 className="contact-address-email">
								<strong>E-mail:</strong>{" "}
								<a href="mailto:f.ferenc@rendberak.hu">f.ferenc@rendberak.hu</a>
							</h4>
						</div>
					</div>

					<div className="contact-description-right">
						<form className="contact-form" onSubmit={handleSubmit}>
							<div className="contact-form-personal">
								{/* This would be a bot field with type="hidden" */}
								<input
									name="a_password"
									placeholder="do not fill this"
									type="hidden"
									tabIndex="-1"
									autoComplete="false"
									onChange={handleStateChange}
									value={mailerState.a_password}
								/>
								<input
									type="text"
									className="contact-input"
									id="name"
									name="name"
									placeholder="Név"
									required
									onChange={handleStateChange}
									value={mailerState.name}
								/>
								<input
									type="email"
									className="contact-input"
									name="email"
									placeholder="Email cím"
									required
									onChange={handleStateChange}
									value={mailerState.email}
								/>
							</div>
							<textarea
								name="message"
								placeholder="Üzenet"
								className="contact-input contact-textarea"
								rows={5}
								onChange={handleStateChange}
								value={mailerState.message}
							/>
							<button
								disabled={loading}
								className={
									"btns contact-btn " +
									(btnSpinLogo ? "lds-dual-ring btn-loading" : "")
								}
							>
								{btnText}
								{defaultBtnIcon}
							</button>
						</form>
					</div>
				</div>
			</section>
			<div className="contact-map">
				<iframe
					title="gmapFrame"
					src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2697.19429044092!2d19.305497078256074!3d47.46664319762902!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4741c0d05a661533%3A0xa4761269d31bd580!2sRendbe-Rak%20Kft.!5e0!3m2!1shu!2shu!4v1690389848772!5m2!1shu!2shu"
					width="100%"
					height="100%"
					style={{ border: "0" }}
					allowFullScreen=""
					key="AIzaSyBEu3nRspFbuan4OMxF41Wt3tf5o_D-5gk"
				></iframe>
			</div>
		</div>
	)
}