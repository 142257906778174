import React from "react";
import { NavLink } from "react-router-dom"

export default function NotFound(){
	return (
		<div className="not-found-wrapper">
			<div className="not-found">
				<div className="not-found-404">
					<h3>A oldal nem létezik</h3>
					<h1>
						<span>4</span>
						<span>0</span>
						<span>4</span>
					</h1>
				</div>
				<h2>Sajnáljuk az ön által keresett oldal nem tatlálható</h2>
				<NavLink className="btn" to="/">
					Főoldal <i className="fa-solid fa-angle-right fa-2xs"></i>
				</NavLink>
			</div>
		</div>
	)
};
