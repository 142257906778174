import React from "react";

export default function Footer(){
	return (
		<section className="footer">
			<div className="footer-wrapper">
				<h3> © Rendbe-Rak Kft.</h3>
				<a
					href="/rendberak-weboldalra-adatkezelesi-tajekoztato-20200625.pdf"
					rel="noopener noreferrer"
					target="_blank"
				>
					Adatvédelmi tájékoztató
				</a>
			</div>
		</section>
	)
};
