import React, { useState } from "react"
import "./nav.css"
import { NavLink } from "react-router-dom";

export default function NavBar() {
	const [checked, setChecked] = useState(false)

	function handleclick(e){
		setChecked(prevState => !prevState)
	}

	return (
		<nav className="sticky">
			<div className="site-title-wrapper">
				<div className="site-title">
					<img
						src="images/rendberak.png"
						alt="Rendbe-Rak Kft logo"
						className="logo-img"
					/>
					<div className="logo-wrap">
						<div className="logo-title">
							<span className="logo-red">Rendbe</span>-Rak Kft.
						</div>
						<span className="logo-year">1992 óta</span>
					</div>
				</div>
			</div>
			<div className="nav-link-group">
				<input
					type="checkbox"
					id="menu-icon"
					checked={checked}
					onChange={handleclick}
					
				/>
				<label className="menu" htmlFor="menu-icon">
					<div className="bar"></div>
					<div className="bar"></div>
					<div className="bar"></div>
				</label>
				<div className="nav-links">
					<NavLink
						onClick={handleclick}
						className={({ isActive }) =>
							isActive ? "active-link" : "inactive-link"
						}
						to="/"
					>
						Főoldal
					</NavLink>
					<NavLink
						onClick={handleclick}
						className={({ isActive }) =>
							isActive ? "active-link" : "inactive-link"
						}
						to="/ajanlat"
					>
						Árajánlat
					</NavLink>
					<NavLink
						onClick={handleclick}
						className={({ isActive }) =>
							isActive ? "active-link" : "inactive-link"
						}
						to="/about"
					>
						Szolgáltatás
					</NavLink>
					<NavLink
						onClick={handleclick}
						className={({ isActive }) =>
							isActive ? "active-link it-szolg" : "inactive-link it-szolg"
						}
						to="/it"
					>
						IT Szolgáltatás
					</NavLink>
					<NavLink
						onClick={handleclick}
						className={({ isActive }) =>
							isActive ? "active-link" : "inactive-link"
						}
						to="/contact"
					>
						Kapcsolat
					</NavLink>
				</div>
			</div>
		</nav>
	)
};
