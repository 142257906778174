import React from "react"
import { NavLink } from "react-router-dom"
import ScrollButton from "../components/ScrollButton";
import "./it.css"

export default function It() {

	const data = [
		{
			id: 1,
			img: "images/it/consult.svg",
			title: "Konzultáció",
			description: `Projekt tervezés, üzemeltetés, optimalizálás`,
		},
		{
			id: 2,
			img: "images/it/server.svg",
			title: "Üzemeltetés",
			description: `Informatikai rendszerek kiépítése, üzemeltetése, karbantartása és fejlesztése`,
		},
		{
			id: 3,
			img: "images/it/data.svg",
			title: "Adatamentés",
			description: `Adatmentések tervezése, optimalizálása, biztonsági felügyelete`,
		},
		{
			id: 4,
			img: "images/it/monitor.svg",
			title: "IT felügyelet",
			description: `Rendszergazdai felügyeleti szolgáltatások`,
		},
		{
			id: 5,
			img: "images/it/network.svg",
			title: "Hálózatépítés",
			description: `vezetékes és wifi hálózatok tervezése, telepítése`,
		},
		{
			id: 6,
			img: "images/it/vpn.svg",
			title: "VPN felügyelet",
			description: `VPN hálózatok tervezése, felügyelete. Home office rendszerek kialakítása`,
		},
	]

	return (
		<div>
			<div className="dummy"></div>
			<ScrollButton />
			<section className="it">
				<div className="it-description">
					<div className="it-description-left">
						<img
							src="images/rendberak.png"
							alt="Rendbe-Rak Kft logo"
							className="logo-img-about"
							loading="lazy"
						/>
					</div>

					<div className="it-description-right">
						<div className="it-description-right-wrap">
							<div className="logo-title about-title-text">
								<span className="logo-red">Rendbe</span>-Rak Kft IT
							</div>
							<h4 className="it-text">
								Cégünk több mint 20 éves tapasztalattal rendelkezik
								vállalkozások informatikai rendszereinek tervezésében,
								bevezetésében és üzemeltetésében.
							</h4>
						</div>
					</div>
				</div>
			</section>
			<section className="it-services">
				<div className="it-services-title">
					<div className="it-services-title-top">
						<h1>IT Szolgáltatásaink</h1>
						<h3>
							vállalatok számára folyamatos támogatást nyújtunk a rendszerek
							működtetése, karbantartása, fejlesztése és optimalizálása terén
						</h3>
					</div>
					<div className="it-services-title-down">
						{data.map((dataEl, index) => (
							<div key={index} className="it-services-box">
								<img src={dataEl.img} alt="" className="it-svg" />
								<div className="it-services-box-title">{dataEl.title}</div>
								<div className="it-services-box-text">{dataEl.description}</div>
							</div>
						))}
					</div>
				</div>
			</section>
			<section className="it-contact-section">
				<div className="it-contact">
					<h2>További információkért keressen minket.</h2>
					<NavLink className="it-contact-btn" to="/contact">
						Kapcsolat <i className="fa-solid fa-angle-right fa-2xs"></i>
					</NavLink>
				</div>
			</section>
		</div>
	)
}