import React from "react"
import ScrollButton from "../components/ScrollButton";
import "./aboutus.css"

export default function AboutUs() {

	const data = [
		{
			id: 1,
			img: "images/about/konyveles.svg",
			title: "Könyvelés",
			description: `Az átvett bizonylatok alapján elvégezzük a könyvvezetést,
							kezeljük a számviteli bizonylatokat`,
		},
		{
			id: 2,
			img: "images/about/berszamfejtes.svg",
			title: "Bérszámfejtés",
			description: `Teljes körű bérszámfejtési és TB ügyintézés`,
		},
		{
			id: 3,
			img: "images/about/tanacsadas.svg",
			title: "Tanácsadás",
			description: `Tanácsadás cég vagy egyéni vállakozás indításához. Igény szerint
						soron követjük a vállalkozás gazdaságosságát és igény esetén
						tanácsunkkal, tervkészítéssel segítjük azt`,
		},
		{
			id: 4,
			img: "images/about/hatarido.svg",
			title: "Bevallások határidőre",
			description: `Bevallások, beszámló határidőre`,
		},
		{
			id: 5,
			img: "images/about/kepviselet.svg",
			title: "Képviselet",
			description: `Hatóságok (NAV, TB, Önkormányzat) előtti képviselet`,
		},
		{
			id: 6,
			img: "images/about/hazhoz.svg",
			title: "Irat átvétel",
			description: `Igény esetén cégének telephelyén, bármely budapesti kerületben
								átvesszük és elhozzuk iratait.`,
		},
	]

	return (
		<div>
			<div className="dummy"></div>
			<ScrollButton />
			<section className="about">
				<div className="about-description">
					<div className="about-description-left">
						<img
							src="images/rendberak.png"
							alt="Rendbe-Rak Kft logo"
							className="logo-img-about"
							loading="lazy"
						/>
					</div>

					<div className="about-description-right">
						<div className="about-description-right-wrap">
							<div className="logo-title about-title-text">
								<span className="logo-red">Rendbe</span>-Rak Kft-ről
							</div>
							<h4 className="about-text">
								Cégünk egy családi vállalkozás, mely 1992 óta végez könyvelési,
								és bérszámfejtési szolgáltatást. Partnereink között
								megtalálhatóak kisebb egyéni vállalkozások, valamint nagyobb
								létszámú cégek is, melyeknek a könyvelésen kívül a
								bérszámfejtését is végezzük. A jogszabályoknak megfelelő,
								pontos, precíz munkát végzünk. Kapcsolattartásban rugalmasak
								vagyunk. Felelősségbiztosítással rendelkezünk.
							</h4>
						</div>
					</div>
				</div>
			</section>
			<section className="about-services">
				<div className="about-services-title">
					<div className="about-services-title-top">
						<h1>Szolgáltatásaink</h1>
						<h3>
							Könyvelés, bérszámfejtés, képviselet egyéni és társas
							vállalkozásoknak
						</h3>
					</div>
					<div className="about-services-title-down">
						{data.map((dataEl, index) => (
							<div key={index} className="it-services-box">
								<img src={dataEl.img} alt="" className="it-svg" />
								<div className="it-services-box-title">{dataEl.title}</div>
								<div className="it-services-box-text">{dataEl.description}</div>
							</div>
						))}
					</div>
				</div>
			</section>
		</div>
	)
}