import React, {useState, useEffect} from "react"
import "react-responsive-carousel/lib/styles/carousel.min.css"
import "./arajanlat.css"
import ScrollButton from "../components/ScrollButton";


export default function Arajanlat() {

	const url =
		process.env.REACT_APP_NODE_ENV === "production"
			? process.env.REACT_APP_PROD_API_URL
			: process.env.REACT_APP_DEV_API_URL

	const options = [
		{ value: "ev", text: "Egyéni vállalkozó (EV)" },
		{ value: "bt", text: "Betéti társaság (Bt)" },
		{ value: "kkt", text: "Közkeresti társaság (Kkt)" },
		{ value: "kft", text: "Korlátolt felelősségü társaság (Kft)" },
		{ value: "rt", text: "Részvény társaság (Rt)" },
	]

	const adoOptionsEv = [
		{ value: "szja", text: "Személyi jövedelemadó" },
		{ value: "atalany", text: "Átalányadó" },
		{ value: "kata", text: "Kisadózó vállalkozások tételes adója (KATA)" },
	]

	const adoOptions = [
		{ value: "tarsasag", text: "Társasági adó" },
		{ value: "kiva", text: "Kisvállalati adó (KIVA)" }
	]

	const [selected, setSelected] = useState("")
	const [loading, setLoading] = useState(false)
	const [btnSpinLogo, setBtnSpinLogo] = useState(false)
	const [btnText, setbtnText] = useState("Küldés ")
	const [defaultBtnIcon, setDefaultBtnIcon] = useState(
		<i className="fa-regular fa-paper-plane fa-lg fa-bold"></i>
	)
	
	const [mailerState, setMailerState] = useState({
		a_password: "",
		name: "",
		telefon: "",
		email: "",
		cegforma: "",
		adozas: "",
		teaor: "",
		kimeno: "",
		bejovo: "",
		bank: "",
		alkalmazott: "",
		afa: "",
		kulker: "",
		ptgep: "",
		cegauto: "",
		megjegyzes: "",
	})

	function resetMailerState() {
		setMailerState({
			a_password: "",
			name: "",
			telefon: "",
			email: "",
			cegforma: "",
			adozas: "",
			teaor: "",
			kimeno: "",
			bejovo: "",
			bank: "",
			alkalmazott: "",
			afa: "",
			kulker: "",
			ptgep: "",
			cegauto: "",
			megjegyzes: "",
		})
	}

	useEffect(() => {
		// Load reCAPTCHA script and initialize
		if(!document.getElementById("captcha")){
			const script = document.createElement("script")
			script.src = `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_SITE_KEY}`
			document.body.appendChild(script)
			script.setAttribute("id", "captcha")

			script.onload = () => {
				// Initialize reCAPTCHA with your site key
				window.grecaptcha.ready(() => {
					// You can use this callback to execute code after reCAPTCHA script is loaded and ready
					setLoading(false)
				})
			}
		}
	}, [])

	const handleChange = (e) => {
		setSelected(e.target.value)
		setMailerState((prevState) => ({
			...prevState,
			[e.target.name]: e.target.value,
		}))
	}
	
	function handleStateChange(e) {
		setMailerState((prevState) => ({
			...prevState,
			[e.target.name]: e.target.value,
		}))
	}

	function handleToChangeBtnCheck() {
		setbtnText("elküldve")
		setDefaultBtnIcon(
			<i className="fa-solid fa-check fa-lg" style={{ color: "#fff" }}></i>
		)
		changeToDefaultBtn()
	}

	function changeToDefaultBtn() {
		setTimeout(() => {
			setDefaultBtnIcon(<i className="fa-regular fa-paper-plane fa-lg"></i>)
			setbtnText("Küldés ")
			setLoading(false)
		}, 4000)
	}

	const handleRecaptcha = async () => {
		if (typeof window !== "undefined" && window.grecaptcha) {
			const token = await window.grecaptcha.execute(
				process.env.REACT_APP_SITE_KEY,
				{
					action: "submit",
				}
			)
			return token
		}
		return ""
	}

	const submitEmail = async (e) => {
		e.preventDefault()
		setLoading(true)
		setDefaultBtnIcon("")
		setbtnText("Küldés...")
		setBtnSpinLogo(true)
		const payload = { ...mailerState }

		try {
			const token = await handleRecaptcha() // Call handleRecaptcha to get the token
			
			if (token) {
				payload.token = token
				const response = await fetch(url + "/offer", {
					method: "POST",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify({ payload }),
				})
				// eslint-disable-next-line
				const res = await response.json()
				// Handle the response from your Node.js API
				setBtnSpinLogo(false)
				resetMailerState()
				handleToChangeBtnCheck()
			} else {
				console.log('no token')
			}
		} catch (error) {
			setBtnSpinLogo(false)
			setbtnText("Hiba, kérjük próbálja később")
			setDefaultBtnIcon(
				<i
					style={{ color: "#fff" }}
					className="fa-solid fa-triangle-exclamation fa-lg"
				></i>
			)
			changeToDefaultBtn()
			resetMailerState()
		}
	}

	return (
		<div>
			<div className="dummy"></div>
			<ScrollButton />
			<section className="services">
				<div className="services-title ajanlat-title">
					<h2>Árajánlat kérés</h2>
				</div>
				<div className="services-description ajanlat-description">
					<h4>Kérjük töltse ki az alábbi kérdőívet:</h4>
				</div>
				<form className="ajanlat-form" onSubmit={submitEmail}>
					{/* This would be a bot field with type="hidden" */}
					<input
						name="a_password"
						placeholder="do not fill this"
						type="hidden"
						tabIndex="-1"
						autoComplete="false"
						onChange={handleStateChange}
						value={mailerState.name}
					/>
					<label className="ajanlat-label" htmlFor="name">
						Név
					</label>
					<input
						type="text"
						className="ajanlat-input"
						id="name"
						name="name"
						required
						onChange={handleStateChange}
						value={mailerState.name}
					/>
					<label className="ajanlat-label" htmlFor="telefon">
						Telefonszám
					</label>
					<input
						type="text"
						className="ajanlat-input"
						name="telefon"
						id="telefon"
						required
						onChange={handleStateChange}
						value={mailerState.telefon}
					/>
					<label className="ajanlat-label" htmlFor="email">
						Email
					</label>
					<input
						type="email"
						name="email"
						id="email"
						className="ajanlat-input"
						required
						onChange={handleStateChange}
						value={mailerState.email}
					/>
					<label className="ajanlat-label" htmlFor="cegforma">
						Cégforma
					</label>
					<select
						className="ajanlat-input"
						name="cegforma"
						id="cegforma"
						onChange={handleChange}
						defaultValue="default"
					>
						<option value="default" disabled hidden>
							-- Kérjük válasszon --
						</option>
						{options.map((option) => (
							<option key={option.value} value={option.value}>
								{option.text}
							</option>
						))}
					</select>
					{!selected ? null : selected === "ev" ? (
						<label className="ajanlat-label" htmlFor="adozas">
							Adózási mód egyéni vállalkozó
						</label>
					) : (
						<label className="ajanlat-label" htmlFor="adozas">
							Adózási mód
						</label>
					)}
					{!selected ? null : selected === "ev" ? (
						<select
							className="ajanlat-input"
							name="adozas"
							id="adozas"
							defaultValue="default"
							onChange={handleChange}
						>
							<option value="default" disabled hidden>
								-- Kérjük válasszon --
							</option>
							{adoOptionsEv.map((option) => (
								<option
									disabled={option.disabled}
									key={option.value}
									value={option.value}
								>
									{option.text}
								</option>
							))}
						</select>
					) : (
						<select
							className="ajanlat-input"
							name="adozas"
							id="adozas"
							defaultValue="default"
							onChange={handleChange}
						>
							<option value="default" disabled hidden>
								--- Kérjük válasszon --
							</option>
							{adoOptions.map((option) => (
								<option
									disabled={option.disabled}
									key={option.value}
									value={option.value}
								>
									{option.text}
								</option>
							))}
						</select>
					)}
					<label className="ajanlat-label" htmlFor="teaor">
						Tevékenységi kör (TEÁOR)
					</label>
					<input
						type="text"
						name="teaor"
						id="teaor"
						className="ajanlat-input"
						onChange={handleStateChange}
						value={mailerState.teaor}
					/>
					<label className="ajanlat-label" htmlFor="kimeno">
						Kimenő számlák (db/hó)
					</label>
					<input
						type="text"
						name="kimeno"
						id="kimeno"
						className="ajanlat-input"
						min="1"
						max="9999"
						onChange={handleStateChange}
						value={mailerState.kimeno}
					/>
					<label className="ajanlat-label" htmlFor="bejovo">
						Bejövő számlák (db/hó)
					</label>
					<input
						type="number"
						name="bejovo"
						id="bejovo"
						className="ajanlat-input"
						min="1"
						max="9999"
						onChange={handleStateChange}
						value={mailerState.bejovo}
					/>
					<label className="ajanlat-label" htmlFor="bank">
						banki kivonatban szereplő tételek (db/hó)
					</label>
					<input
						type="number"
						name="bank"
						id="bank"
						className="ajanlat-input"
						min="1"
						max="9999"
						onChange={handleStateChange}
						value={mailerState.bank}
					/>
					<label className="ajanlat-label" htmlFor="alkalmazott">
						Alkalmazottak száma
					</label>
					<input
						type="number"
						name="alkalmazott"
						id="alkalmazott"
						className="ajanlat-input"
						min="1"
						max="999"
						onChange={handleStateChange}
						value={mailerState.alkalmazott}
					/>
					<label className="ajanlat-label" htmlFor="afa">
						ÁFA bevallás gyakorisága
					</label>
					<select
						className="ajanlat-input"
						name="afa"
						id="afa"
						defaultValue="default"
						onChange={handleChange}
					>
						<option value="default" disabled hidden>
							-- Kérjük válasszon --
						</option>
						<option value="nincs">Nincs ÁFA bevallás</option>
						<option value="havi">Havi ÁFA</option>
						<option value="negyedev">Negyedéves ÁFA</option>
						<option value="ev">Éves ÁFA</option>
					</select>
					<label className="ajanlat-label" htmlFor="kulker">
						Külkereskedelmi tevékenységet folytat?
					</label>
					<select
						className="ajanlat-input"
						name="kulker"
						id="kulker"
						defaultValue="default"
						onChange={handleChange}
					>
						<option value="default" disabled hidden>
							-- Kérjük válasszon --
						</option>
						<option value="igen">Igen</option>
						<option value="nem">Nem</option>
					</select>
					<label className="ajanlat-label" htmlFor="ptgep">
						Pénztárgépet üzemeltet?
					</label>
					<select
						className="ajanlat-input"
						name="ptgep"
						id="ptgep"
						defaultValue="default"
						onChange={handleChange}
					>
						<option value="default" disabled hidden>
							-- Kérjük válasszon --
						</option>
						<option value="igen">Igen</option>
						<option value="nem">Nem</option>
					</select>
					<label className="ajanlat-label" htmlFor="cegauto">
						Cégautók száma
					</label>
					<input
						type="number"
						name="cegauto"
						id="cegauto"
						className="ajanlat-input"
						min="1"
						max="100"
						onChange={handleStateChange}
						value={mailerState.cegauto}
					/>
					<label className="ajanlat-label" htmlFor="megjegyzes">
						Megjegyzés
					</label>
					<textarea
						name="megjegyzes"
						id="megjegyzes"
						className="ajanlat-input"
						rows={3}
						onChange={handleStateChange}
						value={mailerState.megjegyzes}
					/>
					<div className="btns-wrap">
						<button
							disabled={loading}
							className={
								"btns ajanlat-btn " +
								(btnSpinLogo ? "lds-dual-ring btn-loading" : "")
							}
						>
							{btnText}
							{defaultBtnIcon}
						</button>
					</div>
				</form>
			</section>
		</div>
	)
}
