import React from "react"
import { Carousel } from "react-responsive-carousel"
import { Link } from "react-router-dom"

export default function CarouselComp() {

	const slideObj = [
		{
			id: "slide slide-one",
			title: "Könyvelés",
			titleId: "slide-title title-one",
			img: "images/carousel/carousel1.webp",
			link: "/contact"
		},
		{
			id: "slide slide-two",
			title: "Bérszámfejtés",
			titleId: "slide-title title-two",
			img: "images/carousel/carousel2.webp",
			link: "/contact"
		},
		{
			id: "slide slide-three",
			title: "Tanácsadás",
			titleId: "slide-title title-three",
			img: "images/carousel/carousel3.webp",
			link: "/contact"
		},
		{
			id: "slide slide-four",
			title: "IT Szolgáltatás",
			titleId: "slide-title title-four",
			img: "images/carousel/carousel4.webp",
			link: "/it"
		},
	]

  return (
		<Carousel
			autoPlay
			infiniteLoop
			interval="5000"
			transitionTime="500"
			showThumbs={false}
			showStatus={false}
		>
			{slideObj.map((slideEl, index) => (
				<div key={index} className={slideEl.id}>
					<img alt="" src={slideEl.img} loading="lazy" />
					<h5 className={slideEl.titleId}>{slideEl.title}</h5>
					<Link to={slideEl.link} className="kapcsolat-button">
						Kapcsolat <i className="fa-solid fa-angle-right fa-2xs"></i>
					</Link>
				</div>
			))}
		</Carousel>
	)

}
	