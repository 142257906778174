import React from "react";
import { Routes, Route } from "react-router-dom";
import InfoBar from "./components/infobar";
import NavBar from "./components/navbar";
import Home from "./pages/home";
import Arajanlat from "./pages/arajanlat";
import AboutUs from "./pages/aboutus";
import It from "./pages/it";
import Contact from "./pages/contact";
import Footer from "./components/footer";
import NotFound from "./pages/notFound";

function App() {

	return (
		<React.Fragment>
			<InfoBar />
			<NavBar />
			<Routes>
				<Route exact path="/" element={<Home />} />
				<Route exact path="/about" element={<AboutUs />} />
				<Route exact path="/ajanlat" element={<Arajanlat />} />
				<Route exact path="/contact" element={<Contact />} />
				<Route exact path="/it" element={<It />} />
				<Route path="/*" element={<NotFound />} />
			</Routes>
			<Footer />
		</React.Fragment>
	)
}

export default App;
